<template>
  <div class="row">
    <div class="col-md-12">
      <dashboard-box
        :back="true"
        back-component-name="management.expense-permission.index"
      >
        <template v-slot:title>
          <span>{{
            $t(
              id != null
                ? "expense_permission.edit_expense_permission"
                : "expense_permission.create_expense_permission"
            )
          }}</span>
        </template>
        <template v-slot:preview>
          <div class="row d-flex">
            <div class="col-xl-4">
              <custom-multi-select
                :helperText="$t('general.please_select')"
                :model.sync="expense_id"
                :options="expenseOptions"
                :is-inline="true"
                :title="$t('expense.expenses') + ':'"
                class="font-weight-bolder"
                input-max-width="100%"
                input-width="100%"
                width-class="100%"
              />
            </div>
            <div class="col-xl-4">
              <custom-multi-select
                :helperText="$t('general.please_select')"
                :title="$t('scope.Role') + ':'"
                :model.sync="role_id"
                name="ecpense_category"
                :options="roleOptions"
                :not-list="true"
                :is-inline="true"
                :max="1"
                :item-per-row="1"
                class="font-weight-bolder"
                input-max-width="100%"
                input-width="100%"
                width-class="100%"
              ></custom-multi-select>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-5">
            <button-with-icon
              class="mr-3 danger-border-1px"
              @onClick="$router.go(-1)"
              :text="$t('general.cancel')"
              size="lg"
              :icon-name="null"
            ></button-with-icon>
            <button-with-icon
              @onClick="createOrUpdateExpense"
              :text="$t(id != null ? 'general.save' : 'general.create')"
              size="lg"
              :icon-name="null"
              :disabled="!isValidToCreate"
            ></button-with-icon>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from "vuex";
import {
  CREATE_ITEM,
  CURRENT_ITEM,
  ERROR,
  GET_ITEMS,
  ITEMS,
  GET_ITEM_DETAIL_BY_ID,
  LOADING,
  SET_CURRENT_ITEM,
  SUCCESS,
  UPDATE_ITEM_BY_ID,
} from "@/core/services/store/panel-managment/crud/panel.management.module";

import TextInput from "@/assets/components/inputs/TextInput";
import SelectInput from "@/assets/components/inputs/SelectInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import { LANGUAGE } from "@/core/services/store/system-options.module";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";

export default {
  name: "ExpenseCreateEdit",
  components: {
    ButtonWithIcon,
    TextInput,
    SelectInput,
    CustomMultiSelect,
    DashboardBox,
  },
  data() {
    return {
      id: null,
      roles: [],
      expenses: [],
      expense_id: [],
      role_id: null,
    };
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
      success: SUCCESS,
      language: LANGUAGE,
    }),
    isValidToCreate() {
      if (this.expense_id == null || this.role_id == null) return false;

      return true;
    },
    expenseOptions() {
      let data = this.expenses;
      return this.convertArrayToObjectByKey(data, "id", "translations.0.name");
    },
    roleOptions() {
      let data = this.roles;
      return this.convertArrayToObjectByKey(data, "id", "name");
    },
  },

  methods: {
    ...mapMutations({
      setExpense: SET_CURRENT_ITEM,
    }),
    getExpenseData() {
      let payload = {
        url: "api/expenses",
        returnType: "stateless",
      };

      this.$store.dispatch(GET_ITEMS, payload).then((response) => {
        if (response.status) {
          this.expenses = response.data;
        }
      });
    },
    getRolesData() {
      let payload = {
        url: "api/roles",
        returnType: "stateless",
      };

      this.$store.dispatch(GET_ITEMS, payload).then((response) => {
        if (response.status) {
          this.roles = response.data;
        }
      });
    },
    createOrUpdateExpense() {
      let formatedExpense = [];
      this.expense_id.forEach((expense) => formatedExpense.push(+expense));
      let payload = {
        url:
          this.id == null
            ? "api/expense-permission"
            : "api/expense-permission/" + this.id,
        id: this.id,
        contents: {
          expense_id: formatedExpense,
          role_id: +this.role_id,
        },
        redirectRouteName: "management.expense-permission.index",
        successMessage: this.$t(
          this.id == null
            ? "general.successfully_created"
            : "general.successfully_updated"
        ),
      };
      this.$store.dispatch(this.id == null ? CREATE_ITEM : UPDATE_ITEM_BY_ID, payload);
    },
  },
  mounted() {
    this.id = this.$route.params.id;

    if (this.id != null) {
      let payload = {
        url: "api/expense-permission/" + this.id,
        id: this.id,
        redirectRouteOnNotFound: "management.expense-permission.index",
      };

      this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, payload).then((response) => {
        let data = response.data;
        this.role_id = data.role_id;
        this.expense_id = data.expense_id.map((expense) => {
          return String(expense);
        });
      });
    }

    this.getExpenseData();
    this.getRolesData();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      {
        title: this.$t("expense_permission.expense_permission"),
        route: "management.expense-permission.index",
      },
      {
        title: this.$t(
          this.id != null
            ? "expense_permission.edit_expense_permission"
            : "expense_permission.create_expense_permission"
        ),
      },
    ]);
  },
};
</script>

<style scoped>
#dashboard-box {
  padding: 10px;
}
</style>
